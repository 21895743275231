<template>
  <div v-if="exameShow">
    <v-dialog v-model="dialog" persistent max-width="960">
      <v-form lazy-validation v-on:submit.prevent="onSubmit" ref="form">
        <v-card outlined>
          <v-card-title v-if="!titleOff">
            <span class="headline primary--text">Mezzow Resultados</span>
          </v-card-title>
          <v-card-text v-if="!headersOff">
            <v-row dense>
              <v-col cols="12" md="2">
                <v-tooltip open-delay="800" top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-on="!isItToday(exameShow.data) ? on : false"
                      v-bind="attrs"
                    >{{ getDateFormatted(exameShow.data) }}</span>
                  </template>
                  <span> {{ getDateFormattedDisplay(exameShow.data) }} </span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="4">{{ $_.startCase(exameShow.solicitacao.paciente.nome.toLowerCase()) }}</v-col>
              <v-col cols="12" md="4">{{ exameShow.solicitacao.setor.unidade.nome }}</v-col>
              <v-col cols="12" md="2" class="text-center">
                <v-chip dark small label style="width: 125px" class="justify-center"
                  :color="statusColor(`${exameShow.statusExame.id}`) + ' white--text'"
                >
                  <span>{{ exameShow.statusExame.descricao }}</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-list v-if="resultados.length > 0">
              <v-subheader>Resultados Publicados</v-subheader>
              <v-list-item v-for="item in resultados" :key="item.id">
                <v-list-item-avatar>
                  <v-icon color="primary">mdi-file-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>ID Resultado Mezzow: </span>
                    <strong>{{ item.idResultado }}</strong>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn small color="success" class="text-capitalize" :href="webResultadoView(item.idResultado)" target="_blank">Acessar Resultado</v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions class="pr-4">
            <v-spacer></v-spacer>
            <v-btn v-if="possuiPermissao(['ROLE_ADMIN','ROLE_CLIENTE_ADMIN']) && [4,5].includes(exameShow.statusExame.id)"
              color="success" class="text-capitalize" outlined type="submit">Publicar Versão de Laudo</v-btn>
            <v-btn color="primary" class="text-capitalize" outlined :href="webResultadoCartaoAcessoUrl" target="_blank">Cartão de Acesso</v-btn>
            <v-btn color="error" class="text-capitalize" outlined v-if="!cancelBottonOff" @click="close">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog :value="loading" persistent width="300">
      <v-card class="pa-2">
        <v-card-text class="text-center">
          <span class="headline primary--text">
            Publicando Laudo!
          </span>
        </v-card-text>
        <v-card-text class="text-center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { possuiPermissao } from '../../seguranca';
import { getDateFormatted, getDateFormattedDisplay, isItToday } from '../../utils/dateUtils';

export default {
  name: "exame-mezzow-resultado",
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    titleOff: {
      type: Boolean,
      default: false,
    },
    headersOff: {
      type: Boolean,
      default: false,
    },
    cancelBottonOff: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      exame: null,
      exameShow: null,
      loading: false,
    }
  },
  watch: {
    value(value) {
      this.exame = value;
    },
    exame(value) {
      this.$emit("input", value);
      if (value) {
        this.getExameFull()
        this.dialog = true
      }
    },
  },
  computed: {
    resultados() {
      const resultados = this.exameShow?.resultados || []
      if (resultados?.length > 0) {
        return [resultados.filter(a => !a.excluido).sort((a, b) => b.id - a.id)[0]]
      }
      return resultados
    },
    webAdminUrl() {
      return `${process.env.VUE_APP_MEZZOW_WEB_ADMIN_URL}solicitacao/edit/${this.exame.solicitacao.id}`;
    },
    webResultadoCartaoAcessoUrl() {
      return `${process.env.VUE_APP_MEZZOW_RESULTADO_API}resultado/alocar?id=${this.exameShow.id}`
    }
  },
  methods: {
    ...mapActions(["setAlerta"]),
    isItToday(date) {
      return isItToday(date)
    },
    getDateFormatted(date) {
      return getDateFormatted(date)
    },
    getDateFormattedDisplay(date) {
      return getDateFormattedDisplay(date)
    },
    statusColor(status) {
      return status == "1"
        ? "green"
        : status == "2"
        ? "primary"
        : status == "3"
        ? "red"
        : status == "4"
        ? "blue"
        : status == "5"
        ? "orange"
        : "";
    },
    async getExameFull() {
      const self = this
      await self.$http.get(`/exame/${this.exame.id}`)
        .then(response => {
          self.exameShow = response.data
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Não foi possível buscar o exame!",
          })
        })
    },
    saveUpdate() {
      const self = this

      if (self.exameShow.id) {
        self.loading = true
        self.$http.post(`/resultado/exame/publicar`, self.exameShow)
          .then(async response => {
            self.loading = false
            // self.$emit('objectReturn', response.data)
            self.$emit('objectReturn', await self.$http.get(`/exame/${response.data.id}`).then(response => response.data))
            self.setAlerta( {
              tipoAlerta : 'success',
              textAlerta : 'Resultado publicado com sucesso',
            })
            this.close();
          })
          .catch(async () => {
            self.loading = false
            self.setAlerta({
              tipoAlerta : 'error',
              textAlerta : 'Falha ao publicar Exame',
            })
          })
      }
    },
    close() {
      this.exame = null
      this.exameShow = null
      this.dialog = false
    },
    onSubmit() {
      const self = this
      
      if(!self.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos'
        })
        return
      }

      self.saveUpdate();
    },
    possuiPermissao(value) {
      return possuiPermissao(value)
    },
    webResultadoView(idResultado) {
      return `${process.env.VUE_APP_MEZZOW_RESULTADO_URL}/#resultado/${idResultado}`
    }
  },
  created() {}
};
</script>